<template>
    <div class="genLeague-page">
        <span class="color-word">敬请期待</span>
    </div>
   

</template>
<script>
export default {
    name: 'genLeague',
    data() {
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
    .genLeague-page {
        width: 100%;
        text-align: center;
        .color-word {
            display: inline-block;
            margin-top: 10%;
            text-align: center;
            font-size: 0.48rem;
            letter-spacing: 0.64rem;
            color: transparent;
            background: linear-gradient(to right, #9495FB 0%, #EAAAE4 40%,  #65BAFF 100%);
            background-clip: text;
        }
    }
</style>
